@keyframes adp-e-loader-animation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.adp-e-loader {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100%;

	svg {
		animation: adp-e-loader-animation 1s infinite linear;
	}
}
