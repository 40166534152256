@import '../variables/general';

.adp-e-mobile-header {
	display: flex;
	grid-area: header;
	justify-content: center;
	align-items: center;
	background-color: var(--alias-tile-background-secondary);
	text-align: center;
	position: relative; // fixes bug with chat bubbles overlaying box-shadow
	z-index: 20;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
	padding: 11px 0;
	height: $header-height;

	.page-title-elements {
		flex: 1 1 0%;
		display: flex;
		align-items: center;
		h1 {
			font-weight: var(--alias-tile-font-weight-bold);
			font-size: min($font-size-x-large, 32px);
			text-align: left;
			margin: 0 10px 0 10px;
		}
	}

	.header-icon {
		flex: none;
		margin-right: 20px;
		margin-top: 3px;
		border: none;
		padding: 0;
		background: none;
		font-size: min(var(--size-font-base), 32px);

		&:focus {
			outline: none;
		}
	}

	.header-icon-support-icon.mobile {
		max-width: initial;
		border: 1px solid var(--alias-tile-border-secondary);
		border-radius: 30px;
		padding: 2px 10px;
		margin-top: 0;
		font-weight: $font-weight-medium;
		font-family: $font-family-default;
		font-size: min(var(--size-font-base), 32px);
		display: flex;
		justify-content: center;
		align-items: center;

		&.header-icon-live-chat-active {
			color: var(--alias-font-link-primary);
		}

		span {
			margin-right: 8px;
		}
	}

	.client-title {
		display: flex;
		text-align: left;
		margin: 3px 10px 0 10px;
		color: var(--alias-font-link-primary);
		background: none;
		border: none;
		padding: 0;
		font-size: var(--size-font-xs);
		font-weight: var(--font-weight-semibold);

		p {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: inline-block;
			max-width: 150px;
		}

		svg {
			margin-top: 0.15rem;
		}
	}

	.header-icon-live-chat-visible {
		color: var(--alias-tile-font-secondary);
	}

	.header-icon-live-chat-active {
		color: var(--alias-font-danger);
	}

	&.impersonation {
		background-color: var(--alias-background-highlight);
		color: var(--alias-tile-font-neutral);
	}

	&.view {
		background-color: var(--color-core-green-600);
		color: var(--alias-tile-font-neutral);
	}

	.end-session-button {
		background-color: var(--alias-tile-background-secondary);
		border-radius: 14px;
		color: var(--alias-font-link-primary);
		cursor: pointer;
		height: 60%;
		justify-content: center;
		margin: auto 5px;
		padding: 0 30px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-khtml-user-select: none;
		-ms-user-select: none;

		svg {
			position: relative;
			left: -20px;
		}
	}
}

.adp-e-desktop-header {
	background-color: transparent;
	box-shadow: none;

	h1 img {
		position: relative;
		left: 10px;
	}
}

.header-icon.header-icon-support-icon.mobile.animating {
	animation: breath 1.5s ease-out normal;
}

@keyframes breath {
	20% {
		transform: scale(1.2);
	}

	80% {
		transform: scale(1.2);
	}

	100% {
		transform: scale(1);
	}
}
