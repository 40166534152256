.error-view-container {
	.error-content {
		justify-content: center;
		display: flex;
		flex-grow: 1;
		flex-flow: column wrap;
	}

	.error-icon {
		text-align: center;
		transform: rotate(180deg);
	}

	.error-title {
		color: $gray;
		font-family: $font-family-default;
		font-size: $font-size-x-large;
		font-weight: $font-weight-bold;
		text-align: center;
	}

	.error-message {
		color: $gray;
		font-family: $font-family-default;
		font-size: $font-size-default;
		font-weight: $font-weight-default;
		margin-top: 30px;
	}

	p {
		margin: 15px 50px 0px 50px;
		font-size: $font-size-large;
		text-align: center;
	}

	.adp-e-mobile-content {
		background-color: var(--alias-background-primary);
	}

	.adp-e-chat-ui-input-components {
		-webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
		box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
		justify-content: flex-end;
	}
}
