@import '../variables/general';

.qr-code-reader-view-layout {
	.adp-e-mobile-header {
		height: $header-height;
	}
}

.qr-code-reader-view-container {
	height: $content-area-default;

	.adp-e-mobile-content {
		background: transparent;

		.qr-code-reader-host-banner {
			text-align: center;
			padding: 25px 0px;
			background-color: var(--alias-background-primary);
		}
	}
}

.qr-code-reader {
	background: transparent;
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: center;

	.qr-code-reader-box {
		width: 200px;
		height: 200px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-top: -100px;

		.qr-code-reader-box-top {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			div {
				border: 1px solid var(--alias-border-neutral);
				border-bottom: none;
				height: 50px;
				width: 50px;
			}

			.qr-code-reader-box-left {
				border-right: none;
			}

			.qr-code-reader-box-right {
				border-left: none;
			}
		}

		.qr-code-reader-box-bottom {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			div {
				border: 1px solid var(--alias-border-neutral);
				border-top: none;
				height: 50px;
				width: 50px;
			}

			.qr-code-reader-box-left {
				border-right: none;
			}

			.qr-code-reader-box-right {
				border-left: none;
			}
		}
	}
}

.qr-code-reader-close {
	color: $primary-500;
	padding: 20px;
	display: block;
	text-align: center;
}
