.action-modal {
	display: none; /* Hidden by default */
	position: absolute; /* Stay in place */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
	-webkit-animation-name: fadeIn; /* Fade in the background */
	-webkit-animation-duration: 0.4s;
	animation-name: fadeIn;
	animation-duration: 0.4s;
	
	&.action-modal--open {
		z-index: 9990; /* Sit on top */
		display: block;
	}

	&--desktop {
		border-radius: 15px;
	}

	&.slide-from-top {
		.modal-content {
			top: 0;
			bottom: unset;
			height: fit-content;
		}
	}
}

.modal-content {
	position: absolute;
	bottom: 0;
	background-color: var(--component-timeline-background-primary);
	width: 100%;
	-webkit-animation-name: slideIn;
	-webkit-animation-duration: 0.4s;
	animation-name: slideIn;
	animation-duration: 0.4s;

	.modal-content-warning {
		padding: 15px;
		text-align: justify;
	}
}

@-webkit-keyframes slideIn {
	from {
		bottom: -300px;
		opacity: 0;
	}

	to {
		bottom: 0;
		opacity: 1;
	}
}

@keyframes slideIn {
	from {
		bottom: -300px;
		opacity: 0;
	}

	to {
		bottom: 0;
		opacity: 1;
	}
}

@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
