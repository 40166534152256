/**
  * General layout dimensions & variables
  *
  */
$presentation-width: 100%;
$segment-width: 75%;
$icon-height: 24px;
$icon-width: $icon-height;
$address-selector-map-height: 180px;
$address-selector-icon-size: 16px;
$cancel-icon-size: 12px;
$main-nav-height: 55px;
$header-height: 55px;


$content-area-default: calc(
	100vh - #{$main-nav-height} - #{$header-height} - env(safe-area-inset-bottom, 0px)
);
$content-area-no-footer: calc(100vh - #{$header-height} - env(safe-area-inset-bottom, 0px));

$desktop-header-height: 60px;
$desktop-footer-height: 60px;
$desktop-title-header: 55px;
