.adp-e-mobile-timeline {
	&-container {
		position: relative;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		scroll-behavior: smooth;
		height: 100%;
	}
}

.timeline-view-container {
	.adp-e-mobile-content {
		background-color: var(--alias-background-primary);
	}
}
