.live-chat-container {
	.live-chat-iframe-container {
		position: absolute;
		background-color: var(--alias-background-primary);
		top: 55px;
		width: 100%;
		// cover 1 extra pixel beyond the heights of header & nav bar to close gap
		height: calc(100vh - 55px - 54px - env(safe-area-inset-bottom, 0px));
		z-index: 10;
		padding: 0px;
		overflow: hidden;
		contain: content;

		iframe#iframe-live-chat {
			width: 100%;
			height: calc(100vh - 70px - 40px - env(safe-area-inset-bottom, 0px));
		}
	}

	.live-chat-offscreen {
		visibility: hidden;
	}

	.live-chat-visible {
		left: 0px;
	}
}

.adp-e-mobile-keyboard-visible {
	.live-chat-container {
		.live-chat-iframe-container {
			height: calc(100vh - 50px - env(safe-area-inset-bottom, 0px)) !important;
			padding-bottom: 10px;
		}
		iframe {
			height: calc(100vh - 50px - env(safe-area-inset-bottom, 0px)) !important;
		}
	}

	.live-chat-tooltip-container {
		&-border-node {
			height: calc(100vh - 86px - env(safe-area-inset-bottom, 0px)) !important;
		}

		.live-chat-iframe-container {
			.iframe-cimplicity {
				height: calc(100vh - 86px - env(safe-area-inset-bottom, 0px)) !important;
			}
		}
	}
}

.live-chat-tooltip-container {
	background-color: transparent;
	position: fixed;
	right: -9999px;
	top: 5px;
	width: calc(50% - 78px);
	max-width: 370px;
	z-index: 9999;

	&-border-node {
		position: relative;
		background-color: var(--alias-background-primary);
		box-shadow: 0 2px 10px 0 rgb(0 0 0 / 40%);
		height: 611px;
		overflow: hidden;
		padding: 0px;
		width: calc(100% - 10px);
		border-radius: 14px;
		border-collapse: collapse;
		border: solid 10px var(--alias-avatar-border-secondary);
		contain: content;
	}

	&.live-chat-offscreen {
		right: -9999px;
		visibility: hidden;
	}

	&.live-chat-visible {
		right: 15px;
		top: 65px;
	}

	.live-chat-tooltip-container-arrow {
		position: absolute;
		right: 25px;
		top: -19px;
		z-index: 31;

		&:before,
		&:after {
			border: solid transparent;
			content: ' ';
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-width: 10px;
		}

		&:after {
			border-bottom-color: var(--alias-avatar-border-secondary);
			margin-left: -30px;
		}

		&:before {
			border-bottom-color: var(--alias-avatar-border-secondary);
			margin-left: -29px;
		}

		&.live-chat-offscreen {
			right: -9999px;
			visibility: hidden;
		}
	}

	.live-chat-iframe-container {
		height: 690px;
		.iframe-rocket-chat-live {
			position: relative;
			width: calc(100% + 36px);
			left: -18px;
			top: -11px;
			height: 634px;
		}
		.iframe-cimplicity {
			position: relative;
			width: 100%;
			left: 0px;
			top: 0px;
			height: 592px;
		}
	}
}
