// Normalize @ necolas.github.io/normalize.css/
@import '~normalize-scss';

// Design system variables need to atop this file
@import '~@adplabs/adp-e-common/common/styles/index';

// TODO: Get dark mode sorted for adp-e-desktop-workspace and move this back to adp-e-common
// -> adp-e-platform/modules/adp-e-common/packages/common/styles/index.scss line ~11
@import '~@adplabs/e-design-tokens/dist/variables-dark.css';
@import '~@adplabs/e-design-tokens/dist/variables.scss';

// adp-e-front-end imports
@import 'base';
@import './variables/icons-list';
@import 'components/index';
@import 'views/index';
@import './DesktopLayout.scss';

// adp-e-chat-client imports
@import '~@adplabs/adp-e-chat-client/src/styles/main';

// adp-e-common imports
@import '~@adplabs/adp-e-common/ui-components/styles/adp-e-components';
@import '~@adplabs/adp-e-common/ui-input/styles/components/index';
@import '~@adplabs/adp-e-common/ui-display/styles/components/index';
@import '~@adplabs/adp-e-common/ui-search/styles/index';
@import '~@adplabs/adp-e-common/ui-people/styles/main';

/**
  * adp-e-mobile container and layout in CSS grids
  *
  */

.adp-e-mobile {
	&-container {
		grid-template-rows: [header] $header-height [content] $content-area-default [footer] $main-nav-height;
		grid-template-areas: 'header' 'content' 'footer';
	}

	/**
	* Labeling Template Areas
	*/

	&-header {
		// More in Header.scss
		grid-area: header;
	}

	&-view-container {
		position: relative;
		&.timeline {
			.adp-e-mobile-content {
				height: calc(
					100vh - $main-nav-height  - env(safe-area-inset-bottom, 0px)
				   )
			}
		}
	}

	&-content {
		grid-area: content;
		height: $content-area-default;
		width: 100%;
		overflow: hidden;
		display: flex;
		flex-flow: column nowrap;
		flex-shrink: 0;
	}

	&-footer {
		background-color: var(--alias-tile-background-secondary);
		grid-area: footer;
		height: calc(#{$main-nav-height} + env(safe-area-inset-bottom, 0px));
		box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);

		&.vertical {
			// height: 100vh;
			background: transparent;
		}

		display: flex;
		flex-flow: column nowrap;
		flex-shrink: 0;

		// Allows the footer drop shadow to cover content
		position: relative;
		padding-top: 0px;
		padding-bottom: calc(env(safe-area-inset-bottom, 0px));
	}
}

/**
  * Cordova plugin for when window.cordova & Keyboard
  * These are the rules that for when that happens
  *
  */
.adp-e-mobile-keyboard-visible {
	.adp-e-mobile {
		&-container {
			grid-template-rows: [header] 50px [content] $content-area-no-footer;

			// Removing footer from CSS grid
			grid-template-areas: 'header' 'content';
		}

		&-content {
			height: $content-area-no-footer;
		}
	}
}

/**
  * Some best-practice CSS that's useful for most apps
  * Just remove them if they're not what you want
  * Note: We should put these in their own CSS sheets - FS
  *
	*/

/**
* Fix to weird flash on click
*/

* {
	-webkit-tap-highlight-color: transparent;
}

html {
	box-sizing: border-box;
	overflow: hidden;
	font-feature-settings: "calt" off;
}
/**
 * Tell iOS to use it's native font for scaling, but is overriden below by our typeface
 * More @ https://dev.to/colingourlay/how-to-support-apple-s-dynamic-text-in-your-web-content-with-css-40c0
 *
*/
@supports (font: -apple-system-body) {
	html {
		font: -apple-system-body;
	}
}

// These properties make space for the notch and home indicator for iPhone's with FaceID
body {
	padding-top: env(safe-area-inset-top, 0px);
	padding-bottom: env(safe-area-inset-bottom, 0px);
}

html,
body {
	height: 100%;
	border: 0;
	font-family: $font-family-default;
	font-weight: $font-weight-default;
	color: var(--alias-font-primary);
	line-height: 1.25rem;
	margin: 0;
	padding: 0;
}

a {
	/* Stop Popup Menu */
	-webkit-touch-callout: none;
}

.error {
	color: var(--alias-font-danger);
}

.hidden,
::-ms-clear {
	display: none;
}

#root {
	height: 100vh;
	overflow: hidden;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

svg {
	/* Make SVG fit inside of parent container */
	width: inherit;
	height: inherit;
}

@each $icon in $icon-list {
	.#{$icon} {
		background-image: url('../assets/svg/#{$icon}.svg');
	}
}

.ReactModal__Body--open {
	overflow-y: hidden;
}

img,
p {
	user-drag: none;
	user-select: none;
	-moz-user-drag: none;
	-moz-user-select: none;
	-ms-user-drag: none;
	-ms-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
}

.app-container {
	width: 100%;
	color: $neutral-800;

	.app-content-pane {
		margin-top: 50px;
		padding: 10px;
		position: relative;
	}
}

.settings-group {
	margin-bottom: 14px;

	.settings-group-name {
		font-size: 14px;
		margin-bottom: 5px;
		font-weight: 600;
		color: $neutral-800;
	}
}

.settings-actions {
	margin-top: 25px;
	height: 40px;

	button {
		float: right;
		font-size: var(--size-font-2xs);
		width: auto;
		padding: 9px;
	}
}

// For ios styling
.ios-embedded {
	nav {
		height: 60px !important;
		padding-top: 10px !important;
	}

	.app-container {
		.app-content-pane {
			margin-top: 60px;
		}
	}

	.Search__search_results {
		margin-top: 60px;
		-webkit-transform: translate3d(0, 0, 0);
	}

	.LoginView__wrapper {
		padding: 60px 0 50px 0;
	}

	#login-view-container {
		margin-top: 0px;
	}

	#timeline-view-container {
		margin-top: 60px !important;
	}

	#timeline-filtered-view {
		margin-top: 60px !important;

		.PersonInfo__more-info {
			top: 105px;
		}

		.PersonInfo__detailed-info {
			top: 125px !important;
		}
	}

	#groups-view-container {
		margin-top: 60px;
	}

	#eChat {
		top: 60px !important;
	}
}

.conversation-dev-error {
	bottom: 0;
	position: absolute;
	width: 100%;
	color: var(--alias-tile-font-neutral);
	background-color: $error;
	z-index: 1000;
	opacity: 0.9;
	max-height: 360px;
	overflow-y: auto;

	.Presentation__msg-e {
		text-align: left;
		padding: 5px;
	}
}

// disable select only for mobile 
.adp-e-mobile-container {
	.adp-e-mobile-view-container {
		.people-view-container,
		.timeline-view-container {
			-webkit-touch-callout: none;
			-webkit-user-drag: none;
			-webkit-user-select: none;
			/* Chrome/Safari */
			-moz-user-select: none;
			/* Firefox */
			-ms-user-select: none;
			/* IE10+ */
			-o-user-select: none;
			user-select: none;
		}
	}
}

.adp-e-mobile-expanded {
	&-container {
		position: relative;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
		align-content: stretch;
		align-items: center;
		width: 70%;
		height: calc(100vh - $desktop-header-height);
		padding: 20px 0;
		margin: auto;
		border: none;
		background: none;
		z-index: 99;
		@media only screen and (min-device-width : 768px) and (max-device-width : 1366px) and (orientation : landscape) {
			width: 94%;
		}
		&:focus {
			outline: none;
		}

		&.mobile-ios {
			// border-top: solid 1px var(--alias-border-secondary);
		}
	}

	&-container-panel {
		order: 0;
		flex-grow: 1;
		flex-basis: auto;
		align-self: auto;
		height: 100%;
		// overflow-x: auto;
		border: none;
		border-radius: 15px;
		padding: 0;
		margin-left: 10px;
		background: transparent;

		&:focus {
			outline: none;
		}

		.adp-e-mobile-navigation.vertical {
			.adp-e-mobile-navigation-bottom {
				background: var(--alias-background-primary);
				border-radius: 15px;
				padding: 10px 0;
				box-shadow: 0 2px 10px 0 rgb(0 0 0 / 40%);

				.nav-item {
					font-size: var(--size-font-3xl);
					svg {
						font-size: var(--size-font-2xl);
					}
				}
			}
		}
	}
	// background: var(--alias-background-primary);
	&-container-panel:nth-child(1) {
		border-right: solid 1px var(--alias-border-secondary);
		width: 60px;
		flex: none;
		margin-left: 0;
		margin-right: 25px;
		display: flex;
		flex-direction: column;
		border: none;
		background: transparent;

		.adp-e-mobile-footer {
			height: 100%;
			box-shadow: none;
			padding-bottom: 0;
		}
	}

	&-container-panel:nth-child(2) {
		border-right: solid 1px var(--alias-border-secondary);
		width: calc(50% - 37px - 9px);
		flex: 1 1 auto; /* flex-grow flex-shrink flex-basis - resizable */
		border: 0;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		overflow-y: auto;
		box-shadow: 0 2px 10px 0 rgb(0 0 0 / 40%);

		.adp-e-mobile-header {
			justify-content: space-between;
			background: transparent;
			box-shadow: none;
			color: var(--color-core-black);
			height: $desktop-title-header;
			font-size: var(--size-font-xl);

			.header-icon.search-icon {
				border-radius: 15px;
				margin-right: 0;
				font-size: var(--size-font-xl);
				background: var(--alias-background-primary);
				padding: 12px 15px 8px;
				justify-content: center;
				box-shadow: 0 2px 10px 0 rgb(0 0 0 / 40%);
			}
		}

		.adp-e-mobile-expanded-main-content-panel {
			height: 100%;
			// overflow-x: auto;
			border-radius: 15px;
			box-shadow: 0 2px 10px 0 rgb(0 0 0 / 40%);
			background: var(--alias-background-primary);

			.people-view-container {
				.adp-e-mobile-expanded-main-content-panel {
					border-radius: 0;
					box-shadow: none;
					margin-top: 0;
				}
			}

			&.qr-code-reader-view-layout {
				margin-top: calc($desktop-title-header + 5px);
			}

			.chat-view-container,
			.timeline-view-container,
			.people-view-container,
			.error-view-container,
			.qr-code-reader-view-container {
				height: 100%;
			}

			.adp-e-mobile-content {
				border-radius: 15px;
				height: 100%;
			}
		}
	}

	// splitter
	&-container-panel:nth-child(3) {
		margin: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 0 0 auto;
		background: transparent;
		cursor: col-resize;
		color: var(--color-core-black);
		font-size: var(--size-font-4xl);

		&:hover {
			color: var(--alias-tile-font-neutral);
		}

		.dots {
			font-size: 40px;
		}
	}

	&-container-panel:nth-child(4) {
		margin: 0;
		width: calc(50% - 37px - 9px);
		flex: 0 0 auto; /* flex-grow flex-shrink flex-basis */
		max-width: 70%;
		min-width: 250px;
		height: 100%;
		overflow-y: auto;
		align-self: self-end;
		background: var(--alias-background-primary);
		box-shadow: 0 2px 10px 0 rgb(0 0 0 / 40%);

		.adp-e-mobile-expanded-main-content-panel, 
		.people-view-container,
		.adp-e-mobile-content,
		.adp-e-mobile-content > div	 {
			height: 100%;
		}
	}

	&-main-content-panel {
		position: relative;

		.adp-e-mobile-header {
			position: absolute;
			top: 0;
			right: 0;
			background-color: transparent;
			border: none;
			box-shadow: none;

			.search-icon,
			.header-icon-support-icon {
				box-shadow: 2px 3px 8px 0px $gray;
			}

			.search-icon {
				background-color: var(--alias-background-primary);
				border-radius: 50%;
				height: 33px;
				justify-content: center;
				line-height: 40px;
				margin-right: 30px;
				max-width: 33px;
				width: 33px;
				min-width: 33px;
			}

			.loading-icon {
				height: 33px;
				justify-content: center;
				line-height: 40px;
				margin-right: 30px;
				max-width: 33px;
				width: 33px;
				min-width: 33px;
			}
		}

		.adp-e-mobile-content {
			.chat-container {
				height: 100%;

				.chat-standalone {
					height: 100%;
				}
				.scroll-bottom {
					position: absolute;
					right: 20px;
				}
			}
		}

		.search-container {
			.search-header {
				position: static;
				width: 100%;
				border: solid 1px var(--alias-border-neutral);
			}

			.search-loading-bar {
				position: relative;
				top: 0px;
				z-index: 0;
			}

			.search-view-container {
				position: static;
				width: 100%;
				margin-top: 0px;
			}
		}

		.adp-e-mobile-expanded-frame {
			margin-left: 10px;
		}
	}

	&-frame {
		width: 100%;
		height: 100%;
	}

	&-container-tile {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		-webkit-overflow-scrolling: touch;
		scroll-behavior: smooth;
		padding: 13px 0 5px 0;
	}
}

// keyboard login styles go here

.adp-e-mobile-keyboard-visible {
	.adp-e-auth-container {
		h3,
		footer {
			display: none;
		}
	}
}

.adp-e-auth-container {
	position: relative;
	background: var(--component-login-background-primary);
	z-index: 0;
	padding: 0 25px;
	height: 100%;
	color: var(--alias-font-tertiary);
	overflow: hidden;

	.chat-logo-background {
		width: 60rem;
		max-width: 1140px;
		height: 60rem;
		max-height: 1140px;
		z-index: 1;
		color: var(--alias-font-highlight);
		position: absolute;
		left: 50%;
		top: calc(-282px - env(safe-area-inset-bottom, 0px));
		transform: translateX(-31%);
	}

	.content-section {
		position: relative;
		height: 100vh;
		z-index: 2;
		max-width: 400px;
		margin: 0 auto;
		overflow-y: auto;
	}

	.logo {
		@media screen and (max-height: 620px) {
			display: none;
		}

		&.login-options {
			text-align: center;
			display: block;
		}
	}

	#roll-logo-tm {
		width: 80px;
		height: 80px;
	}

	.login-options {
		text-align: center;

		#roll-logo-tm {
			width: 160px;
			height: 160px;

			@media screen and (max-height: 640px) {
				width: 100px;
				height: 100px;
			}
		}

		h2 {
			font-size: var(--size-font-lg);
			line-height: 27px;
			font-weight: $font-weight-regular;
			width: 65%;
			margin: 0 auto;
			margin-bottom: 32px;

			@media screen and (max-height: 585px) {
				width: 100%;
			}

			@media screen and (max-width: 280px) {
				width: 100%;
			}
		}

		h3 {
			font-size: 22px;
			color: var(--alias-tile-font-neutral);
			font-weight: $font-weight-bold;
			margin-top: 0;
			margin-bottom: 40px;

			@media screen and (max-height: 560px) {
				display: none;
			}
		}

		button {
			font-family: $font-family-default;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 22px 0;
			border-radius: 25px;
			border: none;
			background-color: var(--alias-button-login-primary);
			box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
				rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
				rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
			width: 100%;
			padding: 14px;

			@media screen and (max-height: 560px) {
				padding: 9px;
			}

			.text {
				font-size: var(--size-font-base);
				font-weight: var(--font-weight-semibold);
				margin-left: 8px;
			}
		}

		.apple-icon,
		.google-icon,
		.email-icon {
			position: relative;
			top: 1px;
		}

		.apple-icon {
			margin-left: 2px;
			@media screen and (max-width: 280px) {
				margin-left: 0;
			}
		}

		.google-icon {
			margin-left: 12px;

			@media screen and (max-width: 280px) {
				margin-left: 0;
			}
		}

		.email-icon {
			margin-left: -4px;
			@media screen and (max-width: 280px) {
				margin-left: 0;
			}

			svg {
				width: 24px;
				height: 24px;
			}
		}
	}

	h2,
	h3 {
		color: var(--component-login-font-primary);
	}

	.form-container {
		background: var(--component-login-background-card-primary);
		border-radius: 25px;
		box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
			rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
			rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
	}

	input {
		background: var(--alias-input-background-primary);
		color: var(--alias-font-primary);
		font-family: $font-family-default;
		padding-left: 10px;
		width: 100%;
		font-size: $font-size-default;

		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:active {
			-webkit-box-shadow: 0 0 0 30px var(--alias-input-background-primary) inset;
			box-shadow: 0 0 0 30px var(--alias-input-background-primary) inset;
			-webkit-text-fill-color: var(--alias-font-primary);
		}
	}

	.sign-in,
	.sign-up,
	.forgot-password,
	.reset-password,
	.signup-confirm {
		#emailAddress,
		#reset-code,
		#enterCode,
		#newPassword,
		#confirmPassword,
		.password-container {
			border-radius: 10px;
			outline: none;
			border: 1px solid var(--alias-border-secondary);
		}

		#emailAddress,
		#reset-code,
		#enterCode {
			height: 50px;
		}

		#password,
		#newPassword,
		#confirmPassword {
			outline: none;
			border: none;
			height: 100%;
		}

		.password-container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: var(--alias-input-background-primary);
			height: 50px;

			input {
				border-radius: 10px;
			}
		}

		.toggle-password-visibility {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%
		}

		.forgot-or-change-password {
			font-family: $font-family-default;
			background: none;
			border: 0;
			height: 48px;
		}

		button {
			font-family: $font-family-default;
			background: none;
			border: 0;
		}
	}

	.forgot-password {
		@media screen and (max-height: 600px) {
			h3 {
				display: none;
			}
		}
	}

	footer {
		width: 100%;
		position: absolute;
		bottom: 10px;
		left: 0;
		text-align: center;
		margin-bottom: 20px;

		ul {
			list-style: none;
			line-height: 0.5rem;
			display: inline-block;
			padding: 0 30px;
			border-radius: 28px;
			margin: 0 auto;
			background: var(--alias-background-primary);
			box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
				0 1px 2px 0 rgba(0, 0, 0, 0.06);
		}

		@media screen and (max-height: 585px) {
			margin-bottom: 0;

			ul {
				padding: 0 10px;
			}
		}

		@media screen and (max-width: 280px) {
			margin-bottom: 0;

			ul {
				padding: 0 10px;
			}
		}

		li {
			display: inline;
			margin: 0 10px;

			@media screen and (max-width: 400px) {
				margin: 0 8px;
			}

			a {
				font-size: $font-size-small;
				color: var(--alias-font-link-primary);
				text-decoration: none;
				display: inline-block;
				height: 40px;
				width: auto;
				text-align: center;
				line-height: 40px;

				&.xxs {
					font-size: $font-size-xx-small;
				}
			}
		}

		.select-language {
			display: flex;
			align-items: center;
			justify-content: center;

			div:first-child {
				font-family: $font-family-default;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 22px 0;
				border-radius: 25px;
				padding: 0 14px;
				background-color: darken($color-core-purple-700, 5%); // dark-mode
				color: $white;

				@media screen and (max-height: 668px) {
					margin: 0 0 5px 0;
				}
			}

			select {
				border: none;
				background-color: darken($color-core-purple-700, 5%); // dark-mode
				color: $white;
				-webkit-appearance: none;
				width: 100%;
				height: 100%;
				line-height: 1.25em;
				padding: 0 8px;
				border-radius: 4px;
				min-height: 30px;
				font-size: var(--size-font-xs);

				&:focus {
					outline: none;
				}

				&.xxs {
					font-size: $font-size-xx-small;
					display: flex;
				}
			}
		}
	}

	.action-button {
		color: var(--alias-tile-font-neutral);

		// copied from .action-button
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 50px;
		border: none;
		border-radius: 4px;
		margin-bottom: 4px !important;

		svg {
			font-size: 30px;
		}
	}
}

/**
  * This is app specific CSS. We're overriding the height of lists here
	* instead of adp-e-common since all components are scoped within
	* .adp-e-chat-ui-input-components in adp-e-common before shipped here
  *
  */
.adp-e-mobile-expanded-container {
	.adp-e-chat-ui-input-components {
		.accordion-list {
			max-height: calc(100vh - 50vh);
		}
	}
}

.select-language-header-control {
	position: relative;
	margin: 0 20px -5px 0;

	select {
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		opacity: 0;
		cursor: pointer;
		width: 100%;

		&:focus {
			outline: none;
		}
	}
}
