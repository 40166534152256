@import '../variables/general';

.adp-e-mobile-navigation {
	display: flex;
	height: 100%;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	align-content: flex-start;
	background: var(--alias-tile-background-secondary);
	text-align: center;
	padding: 15px 40px;
	z-index: 20;

	&.vertical {
		flex-direction: column;
		padding: 0;
		justify-content: flex-end;
		background: transparent;
	}

	.nav-item {
		padding: 10px;
		border: none;
		background: none;

		&:focus {
			outline: none;
		}

		&--active {
			color: var(--alias-panel-font-highlight);

			img {
				border: 1px solid var(--alias-border-highlight);
			}
		}

		&--disabled {
			svg {
				opacity: 0.6
			}
		}
	}

	// These are actually overrides for AccountAvatar 🤷🏻‍♂️
	.avatar {
		margin: 0;
		width: auto;

		.image-container {
			margin: 0;
		}
	}

	svg {
		font-size: 21px;
		color: var(--alias-panel-font-secondary);
		cursor: pointer;
	}
}

// Removing navigation for when window.cordova & Keyboard
.adp-e-mobile-keyboard-visible {
	.adp-e-mobile-navigation {
		display: none;
		visibility: hidden;

		&.vertical {
			display: flex;
			visibility: visible;
		}
	}
}
