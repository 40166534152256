$login-background-color: #5eb9ff;

.adp-desktop-wrapper {
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	box-sizing: border-box;
	position: relative;

	&:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 20%;
        z-index: -1;
        background-image: url("data:image/svg+xml,%3Csvg class='chat-logo-background' version='1.2' baseProfile='tiny' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 45 49' preserveAspectRatio='xMidYMid meet' style=' width: 60rem%3B max-width: 1140px%3B height: 70rem%3B max-height: 1140px%3B z-index: 1%3B color: %2384a8c8%3B'%3E%3Cpath id='IconChatSolid' fill='currentColor' d='M41.013 34.707c6.984 -9.981 4.556 -23.734 -5.425 -30.719c-9.981 -6.985 -23.734 -4.556 -30.719 5.425c-6.141 8.774 -5.09 20.693 2.491 28.26c2.818 2.988 1.596 5.993 -0 8.013c-0.496 0.642 -0.378 1.565 0.264 2.061c0.275 0.212 0.616 0.32 0.963 0.305c14.48 -0.638 23.582 -3.181 30.494 -11.007c0.691 -0.741 1.932 -2.338 1.932 -2.338'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        transform: translate(10%, -2%);
	}

	.adp-desktop-header {
		display: flex;
		width: 100%;
		padding: 10px 20px 10px;
		background: var(--color-core-purple-700);
		align-items: center;
		justify-content: space-between;
		color: var(--color-core-white);
		z-index: 10;
		position: relative;
		height: $desktop-header-height;

		& > * {
			width: 33.333%
		}

		.client-title {
			background: transparent;
			outline: none;
			border: none;
			color: var(--color-core-white);
			font-size: var(--size-font-xl);
			font-weight: var(--font-weight-extrabold);
			font-family: var(--font-family-sans);
			display: flex;
			align-items: center;

			svg {
				color: var(--color-core-blue-200);
				font-weight: var(--font-weight-extrabold);
			}
		}

		.adp-header-menu {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.adp-header-help-menu {
				background: transparent;
				box-shadow: none;

				.header-icon-support-icon.mobile {
					border: 1px solid var(--color-core-white);
					color: var(--color-core-white);
					margin: 0;
					padding: 5px 10px;
				}
			}
		}

		.nav-item {
			padding: 10px;
			border: none;
			background: none;
		}
	}

	.chat-logo-background {
		width: 70rem;
		max-width: 1140px;
		height: 80rem;
		max-height: 1140px;
		z-index: 1;
		color: #84a8c8;
		position: absolute;
		left: 50%;
		top: -280px;
		transform: translateX(-31%);
	}

	.adp-desktop-layover {
		background: $login-background-color;
		height: calc(100vh - $desktop-header-height);
		top: $desktop-header-height;
		position: fixed;
		width: 100vw;
		z-index: 20;
		opacity: 0.8;
	}

	.adp-desktop-content-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.adp-desktop-footer {
		display: flex;
		flex-direction: column;
		text-align: left;
		color: var(--color-core-black);
		font-weight: var(--font-weight-semibold);
		background: transparent;
		height: $desktop-footer-height;
		position: relative;
		z-index: 99;
	}

	.adp-footer-bottom-line {
		display: flex;
		flex-grow: 1;
		justify-content: space-between;
		align-items: flex-end;
		font-size: 14px;
		margin: 20px;
	}

	.adp-copyright-link {
		margin-left: 15px;
		text-decoration: none;
	}

	.adp-e-system-status {
		color: var(--color-core-green-800);
	}
}
