.chat-conversation-tags {
	&-container {
		display: flex;
		flex-direction: column;
		padding: 20px 25px;
	}

	&-section {
		margin-bottom: 40px;

		.btn-toggle-list-all {
			color: var(--alias-font-highlight);
			cursor: pointer;
			display: inline-block;
			margin-left: 8px;
			white-space: nowrap;
			position: relative;
			top: 4px;
			border: none;
			background: none;
			text-decoration: none;
			padding: 0;
		}
	}

	&-title {
		margin-bottom: 20px;
	}

	&-tag {
		border: none;
		border-radius: 15px;
		color: var(--alias-tag-chat);
		cursor: pointer;
		display: inline-block;
		font-size: $font-size-small;
		margin-right: 5px;
		margin-top: 5px;
		padding: 8px 20px;
		white-space: nowrap;
		position: relative;
		height: 31px;

		.busy-indicator {
			position: absolute;
			width: 100%;
			background-color: var(--color-core-neutral-300);
			opacity: 0.6;
			top: 0px;
			left: 0px;
			height: 31px;
			border: none;
			border-radius: 15px;
		}
	}
}
