.people-view {
	&-container {
		-webkit-overflow-scrolling: touch;
		scroll-behavior: smooth;

		.adp-e-mobile-content {
			background-color: var(--alias-avatar-background-primary);

			.adp-e-mobile-timeline-personcard-container {
				display: flex;
				flex-direction: column;
				height: inherit;
				overflow: auto;

				.person-card-header {
					border-bottom: 1px var(--alias-avatar-border-secondary) solid;
				}

				.person-card-header-thin {
					border-bottom: 1px var(--alias-avatar-border-secondary) solid;
				}
				.adp-e-mobile-person-card-toggle {
					&:focus {
						outline: none;
					}
				}
			}
		}
	}
}
