.navigation-menu-popup {
	&-container {
		position: absolute;
		left: 20px;
    	top: 60px;
		z-index: 100;

		&-arrow {
			position: absolute;
			left: 15px;
    		top: -18px;
			z-index: 101;

			&:before,
			&:after {
				border: solid transparent;
				content: ' ';
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-width: 10px;
			}

			&:after {
				border-bottom-color: var(--alias-avatar-border-secondary);
    			margin-left: 4px;
			}

			&:before {
				border-bottom-color: var(--alias-avatar-border-secondary);
				margin-left: 4px;
			}
		}

		&-border-node {
			position: relative;
			background-color: var(--alias-background-primary);
			box-shadow: 0 2px 10px 0 rgb(0 0 0 / 40%);
			overflow: hidden;
			padding: 5px;
			border-radius: 14px;
			border-collapse: collapse;
			border: solid 10px var(--alias-avatar-border-secondary);

			button {
				background-color: transparent;
				border: none;
				color: var(--alias-panel-font-primary);
				cursor: pointer;
				height: 28px;
				list-style-type: none;
				outline: none;
				padding: 7px;
				width: 100%;
				text-align: left;

				&-disabled {
					color: var(--alias-panel-font-disabled);
					background-color: transparent;
				}

				span {
					display: inline-block;
					font-family: $font-family-default;
					font-size: 14px;
					margin-left: 10px;
					white-space: nowrap;
					width: auto;
					position: relative;
					top: -2px;
				}

				&:hover,
				&:focus {
					background-color: var(--alias-panel-background-primary);

					svg {
						color: var(--alias-panel-font-highlight);
					}
				}
			}
		}
	}
}
