.chat-container {
	display: block;
	left: 0;
	width: 100%;
	height: calc(
		100vh - #{$header-height} - #{$main-nav-height} - env(safe-area-inset-bottom, 0px)
	);

	border: none;
	opacity: 1;
	pointer-events: auto;

	&--show {
		left: 0;
		display: block;
		opacity: 1;
		pointer-events: auto;
	}

	&--hide {
		left: -9999px;
		display: none;
		opacity: 0;
		pointer-events: none;
	}
}

.chat-view-layout,
.registration-view-layout {
	.adp-e-mobile-navigation {
		box-shadow: none;
	}
}

.chat-view-container {
	.adp-e-mobile-content {
		background-color: var(--alias-background-primary);
	}
}

// iOS height adjustment for non-iphone
.adp-e-mobile-keyboard-visible {
	.chat-container {
		height: $content-area-no-footer;
	}
	.adp-e-chat-ui-input-components .controls {
		padding-bottom: 15px;
	}
}
