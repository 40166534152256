@import '../variables/general';

.component {
	width: $segment-width;

	&-wrapper {
		text-align: right;
	}
}

.presentation {
	.adp-e-mobile-navigation {
		box-shadow: none;
	}
}

.message-time {
	text-align: center;
	font-size: 12px;
	color: $gray;
	margin-top: 30px;
}

#input {
	flex-shrink: 1;
}

.interaction {
	/* Get height of floated child msg-* items */
	overflow: auto;

	&-wrapper {
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		scroll-behavior: smooth;
		display: flex;
		flex-flow: column nowrap;
		flex: 1;
		padding: 10px;

		.adp-e-mobile-sim-messages {
			display: block;
		}
	}

	&:last-child {
		margin-bottom: 40px;
	}
}

.msg-e:not(:empty),
.msg-u:not(:empty),
.msg-system {
	animation: fadein 0.3s;
	font-size: 12px;
	margin: 20px 0 0 0;
	width: $presentation-width;

	p {
		margin: 0;
	}
}

.msg-e:after,
.msg-u:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: ' ';
	clear: both;
	height: 0;
}

.msg-e {
	text-align: right;
}

.msg-u {
	a div span {
		padding-left: 0 !important;
	}

	a:last-child div span {
		padding-right: 0 !important;
	}

	text-align: left;

	.component {
		float: right;
	}
}

.hidden {
	display: none;
}

.segment {
	overflow: auto;
}

/* Segment Types */
.segue-empty {
	/* Offset margin when segue disappears */
	margin-top: -20px;
}

.msg-system {
	background-color: var(--alias-background-primary);
	font-family: $font-family-default;
	margin-top: 20px;
	overflow: auto;
	padding: 20px 10px;
	width: 100%;

	& + .msg-system {
		margin-top: 2px;
	}
}

.input-component-wrapper {
	position: static;
}

@keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
